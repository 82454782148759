.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@media (max-width: 600px) {
  .fadeInOut-enter-active, .fadeInOut-exit-active {
    transition: opacity 2s ease-in-out; /* Modifica la durata dell'animazione per i dispositivi mobili */
  }

  .App-logo {
    width: 50%; /* Riduci la larghezza del logo per i dispositivi mobili */
    height: auto; /* Imposta l'altezza automatica in modo che l'immagine si ridimensioni proporzionalmente */
  }

  /* Aggiungi altri stili per dispositivi mobili */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fadeInOut-enter {
  opacity: 0;
}

.fadeInOut-enter-active {
  opacity: 1;
  transition: opacity 4s ease-in-out;
}

.fadeInOut-exit {
  opacity: 1;
}

.fadeInOut-exit-active {
  opacity: 0;
  transition: opacity 4s ease-in-out;
}

/* Aggiungi queste regole CSS per il tag video */
video {
  display: block;
  margin: 0 auto;
}

body {
  background-color: #fbfdfa;
  margin: 0;
}